import axios, { AxiosError, AxiosResponse } from "axios";

const sleep = () => new Promise((resolve) => setTimeout(resolve, 250));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep();

    return response;
  },
  (error: AxiosError) => {
    const { data, status } = error.response as AxiosResponse;
    switch (status) {
      case 400:
        if (data.errors) {
          const modelStateErrors: string[] = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateErrors.push(data.errors[key]);
            }
          }
          throw modelStateErrors.flat();
        }
        console.error(data.message);
        break;
      case 401:
        console.error(data.message);
        break;
      case 403:
        console.error("You are not allowed to do that!");
        break;
      case 500:
        console.error("Server error");
        break;
      default:
        break;
    }

    return Promise.reject(error.response);
  }
);

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  getFile: (url: string) =>
    axios.get(url, { responseType: "arraybuffer" }).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, data: FormData) =>
    axios
      .post(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
  putForm: (url: string, data: FormData) =>
    axios
      .put(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
};

const Payees = {
  getAllPayees: (
    page: number,
    rowsPerPage: number,
    sortColumn: string,
    sortOrder: string,
    searchTerm?: string
  ) =>
    requests.get(
      `/Payees?pageIndex=${page}&pageSize=${rowsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    ),
  getPayee: (id: string) => requests.get(`/payees/${id}`),
};

const Bills = {
  getAllBills: (
    page: number,
    rowsPerPage: number,
    sortColumn: string,
    sortOrder: string,
    searchTerm?: string
  ) =>
    requests.get(
      `/Bills?pageIndex=${page}&pageSize=${rowsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    ),
  getAllFailedBills: (
    page: number,
    rowsPerPage: number,
    sortColumn: string,
    sortOrder: string,
    searchTerm?: string
  ) =>
    requests.get(
      `/Bills/GetFailedBills?pageIndex=${page}&pageSize=${rowsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    ),
  getBillByRefCode: (billRefCode: string) =>
    requests.get(`/Bills/GetByRefcode/${billRefCode}`),
};

const Payments = {
  getAllPaymentsByYear: (
    Year: string,
    page: number,
    rowsPerPage: number,
    sortColumn: string,
    sortOrder: string,
    searchTerm?: string
  ) =>
    requests.get(
      `/Tax/1099?year=${Year}&pageIndex=${page}&pageSize=${rowsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`
    ),
  getTaxYear: () => requests.get(`/Tax/taxYears`),
};

const agent = {
  Payees,
  Bills,
  Payments,
};

export default agent;
