import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper as MuiPaper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import agent from "../../utils/axios";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Payee ID",
    width: 220,
    headerAlign: "right",
    align: "right",
    renderCell: (params: GridCellParams) => (
      <Typography variant="body2">
        <Link
          component={NavLink}
          to={`/payees/detail/${params.value}?Status=Paid`}
          color={"black"}
        >
          {String(params.value)}
        </Link>
      </Typography>
    ),
  },
  { field: "payeeName", headerName: "Name", width: 250 },
  { field: "address1", headerName: "Address", width: 250 },
  { field: "address2", headerName: "Address 2", width: 170 },
  { field: "city", headerName: "City", width: 150 },
  { field: "state", headerName: "State" },
  { field: "postalCode", headerName: "Zip Code" },
  {
    field: "amount",
    headerName: "Amount",
    renderCell: (params: GridCellParams) => (
      <Typography variant="body2">
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(params.value as number)}
      </Typography>
    ),
  },
  {
    field: "taxId",
    headerName: "Tax ID",
    renderCell: (params: GridCellParams) => (
      <Typography variant="body2">
        {`XXX-XX-${(params.value as string).slice(-4)}`}
      </Typography>
    ),
  },
];

const currentYear = new Date().getFullYear();
function Payments() {
  const [year, setYear] = useState(currentYear.toString());
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState("payeeId");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  async function getYears() {
    try {
      const response = await agent.Payments.getTaxYear();
      setYears(response);
    } finally {
    }
  }
  async function getPayments(selectedYear: string) {
    try {
      const response = await agent.Payments.getAllPaymentsByYear(
        selectedYear,
        page,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
      setData(response.data); // Adjust based on actual response structure
      setTotalCount(response.count); // Assume totalCount comes from the response
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // Fetch payees data when the component mounts
    getYears();

    getPayments(year);
  }, [page, rowsPerPage, searchTerm, year]);

  const handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
    setLoading(true);
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPage(model.page);
    setRowsPerPage(model.pageSize);
    setLoading(true);
    getPayments(year);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      setOrderBy(model[0].field);
      setOrder(model[0].sort as "asc" | "desc");
      setLoading(true);
      getPayments(year);
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <React.Fragment>
      <Helmet title="Payees" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Payments
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Payments</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={2}>
          <Paper p={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Year"
                onChange={handleChange}
              >
                {years.map((yearOption) => (
                  <MenuItem key={yearOption} value={yearOption}>
                    {yearOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <Paper p={2} mb={4}>
            <TextField
              label="Search Payees"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by Payee ID, Name, Address and email"
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper p={2}>
            <Box sx={{ height: 620, width: "100%" }}>
              <DataGrid
                rows={data}
                loading={loading}
                columns={columns}
                rowCount={totalCount}
                paginationMode="server"
                sortingMode="server"
                pageSizeOptions={[50, 100, 200]}
                paginationModel={{
                  page,
                  pageSize: rowsPerPage,
                }}
                onPaginationModelChange={handlePaginationModelChange}
                onSortModelChange={handleSortModelChange}
                slots={{
                  noRowsOverlay: () => (
                    <div>No payments available for Tax year ending {year}</div>
                  ),
                }}
                initialState={{
                  pagination: {},
                }}
                disableRowSelectionOnClick
                sx={{
                  border: "none", // Remove border
                  "& .MuiDataGrid-columnsContainer": {
                    borderBottom: "1px solid #e0e0e0", // Optional: Add borderBottom to column header if needed
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    display: "none", // Optional: Hide column separators
                  },
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Payments;
